import { ProjectUserRole } from "shared/types/project"
import { defaultAccess } from "./constants"
//
const GLOBAL = "global"
const ALL_ACCESS = "all"
const CONTENT = "Manage Content"
const LEAVE = "Leave Document"
const AMS_CONSULTANT = "AMS Consultant"
const PROJECT_MANAGER = "Project Manager"

const allowedClientStatusRoles = [
  "Lead Consultant",
  "Project Manager",
  "Client User",
  "Client Project Manager",
]

export class AuthHelper {
  // Helper method to check if a role is AMS Consultant or Project Manager
  private static isAmsConsultantOrProjectManager(roleName: string): boolean {
    return roleName === AMS_CONSULTANT || roleName === PROJECT_MANAGER
  }

  // Helper method to get the assigned country roles
  private static getAssignedCountryRoles(
    activeProjectUser: ProjectUserRole,
    activeCountryId: string
  ) {
    return (
      activeProjectUser?.role?.filter(
        (role) =>
          role.countryId === GLOBAL || role.countryId === activeCountryId
      ) || []
    )
  }

  // // Generic access check helper
  // private static hasAccess(
  //   roleId: string,
  //   access: string[],
  //   defaultAccessCheck: string
  // ): boolean {
  //   return access == null || access.length === 0
  //     ? defaultAccess[roleId] === defaultAccessCheck
  //     : access[roleId] === defaultAccessCheck
  // }

  static canViewProjectHomeTab(
    accessRoles: string[],
    activeCountryId: string,
    activeProjectUser: ProjectUserRole
  ): boolean {
    if (activeProjectUser?.role.length === 0) return false

    const accessSet = new Set(accessRoles)

    if (accessSet.has(ALL_ACCESS)) return true

    if (
      activeProjectUser?.role.length === 1 &&
      activeProjectUser?.role[0].countryId === GLOBAL
    )
      return true

    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    return (
      assignedCountry.length > 0 && accessSet.has(assignedCountry[0].role_name)
    )
  }

  static canViewProjectDocumentTab(
    tabName: string,
    activeCountryId: string,
    documentAccess: string[],
    activeProjectUser: ProjectUserRole
  ): boolean {
    if (tabName === CONTENT || tabName === LEAVE) return true

    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    const roleId = assignedCountry[0].id

    if (
      (!documentAccess || documentAccess?.length === 0) &&
      (defaultAccess[roleId] === "maintain" ||
        defaultAccess[roleId] === "maintain_doa")
    )
      return true

    if (
      documentAccess &&
      documentAccess.length > 0 &&
      (documentAccess[roleId] === "maintain" ||
        documentAccess[roleId] === "maintain_doa")
    )
      return true

    return false
  }

  static canViewDocument(
    activeCountryId: string,
    documentAccess: string[],
    activeProjectUser: ProjectUserRole
  ): boolean {
    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    const roleId = assignedCountry[0].id

    if (
      (!documentAccess || documentAccess?.length === 0) &&
      defaultAccess[roleId] !== "none"
    )
      return true

    if (
      documentAccess &&
      documentAccess.length > 0 &&
      documentAccess[roleId] !== "none"
    )
      return true

    return false
  }

  static canSyncCountries(activeProjectUser: ProjectUserRole): boolean {
    if (
      activeProjectUser?.role.length === 1 &&
      activeProjectUser?.role[0].countryId === GLOBAL &&
      this.isAmsConsultantOrProjectManager(activeProjectUser.role[0].role_name)
    )
      return true

    return false
  }

  static canSyncDocuments(
    activeCountryId: string,
    activeProjectUser: ProjectUserRole
  ): boolean {
    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    return this.isAmsConsultantOrProjectManager(assignedCountry[0].role_name)
  }

  static canSyncStructure(
    activeCountryId: string,
    documentAccess: string[],
    activeProjectUser: ProjectUserRole
  ): boolean {
    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    const roleId = assignedCountry[0].id

    if (
      (!documentAccess || documentAccess?.length === 0) &&
      defaultAccess[roleId] !== "maintain" &&
      defaultAccess[roleId] !== "maintain_doa"
    )
      return false

    if (
      documentAccess &&
      documentAccess.length > 0 &&
      documentAccess[roleId] !== "maintain" &&
      documentAccess[roleId] !== "maintain_doa"
    )
      return false

    return true
  }

  static canEditDocument(
    assignedUser: string,
    activeCountryId: string,
    documentAccess: string[],
    isProjectEnvironment: boolean,
    activeProjectUser: ProjectUserRole
  ): boolean {
    if (!isProjectEnvironment) return true

    if (activeProjectUser?.user.id === assignedUser) return true

    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    const roleId = assignedCountry[0].id

    if (
      (!documentAccess || documentAccess?.length === 0) &&
      defaultAccess[roleId] !== "maintain"
    )
      return false

    if (
      documentAccess &&
      documentAccess.length > 0 &&
      documentAccess[roleId] !== "maintain"
    )
      return false

    return true
  }

  static canEditStatus(
    assignedUser: string,
    activeCountryId: string,
    documentAccess: string[],
    activeProjectUser: ProjectUserRole
  ): boolean {
    if (activeProjectUser?.user.id === assignedUser) return true

    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    const roleId = assignedCountry[0].id

    if (
      (!documentAccess || documentAccess?.length === 0) &&
      defaultAccess[roleId] !== "maintain"
    )
      return false

    if (
      documentAccess &&
      documentAccess.length > 0 &&
      documentAccess[roleId] !== "maintain"
    )
      return false

    return true
  }

  static canEditClientStatus(
    assignedUser: string,
    activeCountryId: string,
    documentAccess: string[],
    activeProjectUser: ProjectUserRole
  ): boolean {
    if (activeProjectUser?.user.id === assignedUser) return true

    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    if (allowedClientStatusRoles.includes(assignedCountry[0].role_name))
      return true

    const roleId = assignedCountry[0].id

    if (
      (!documentAccess || documentAccess?.length === 0) &&
      defaultAccess[roleId] !== "maintain"
    )
      return false

    if (
      documentAccess &&
      documentAccess.length > 0 &&
      documentAccess[roleId] !== "maintain"
    )
      return false

    return true
  }

  static canSeeClientComments(
    assignedUser: string,
    activeCountryId: string,
    activeProjectUser: ProjectUserRole
  ): boolean {
    if (activeProjectUser?.user.id === assignedUser) return true

    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    return allowedClientStatusRoles.includes(assignedCountry[0].role_name)
  }

  static canAddChapter(
    activeCountryId: string,
    documentAccess: string[],
    activeProjectUser: ProjectUserRole
  ): boolean {
    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    const roleId = assignedCountry[0].id

    if (
      (!documentAccess || documentAccess?.length === 0) &&
      defaultAccess[roleId] !== "maintain" &&
      defaultAccess[roleId] !== "maintain_doa"
    )
      return false

    if (
      documentAccess &&
      documentAccess.length > 0 &&
      documentAccess[roleId] !== "maintain" &&
      documentAccess[roleId] !== "maintain_doa"
    )
      return false

    return true
  }

  static canViewChapterSettings(
    assignedUser: string,
    activeCountryId: string,
    documentAccess: string[],
    activeProjectUser: ProjectUserRole
  ): boolean {
    if (activeProjectUser?.user.id === assignedUser) return true

    const assignedCountry = this.getAssignedCountryRoles(
      activeProjectUser,
      activeCountryId
    )

    if (assignedCountry.length === 0) return false

    const roleId = assignedCountry[0].id

    if (
      (!documentAccess || documentAccess?.length === 0) &&
      defaultAccess[roleId] !== "maintain" &&
      defaultAccess[roleId] !== "maintain_doa"
    )
      return false

    if (
      documentAccess &&
      documentAccess.length > 0 &&
      documentAccess[roleId] !== "maintain" &&
      documentAccess[roleId] !== "maintain_doa"
    )
      return false

    return true
  }
}
